body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1f2332;
}

.container {
  width: 393px;
  background: #1f2332;
}

@media (max-width: 430px) {
  body {
    display: block;
  }
  .container {
    width: 100%;
    height: 100%;
  }
}

