.navication {
  position: fixed;
  bottom: 0;
  height: 92px;
  display: flex;
  justify-content: space-around;
  width: 100%;
  background: #1f2332;
  padding-top: 15px;
}
