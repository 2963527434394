.bids {
  margin-top: 90px;
  margin-bottom: 110px;
}

.title-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
}

.title-header > p {
  margin: 0;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  color: #ffffff;
  margin-right: 7px;
}

.title-header::after {
  content: "";
  position: absolute;
  width: 90%;
  top: 142px;
  border-radius: 10px;
  border: 1px solid #ffffff1a;
}

.center {
  display: flex;
  justify-content: center;
}

.today {
  height: 36px;
  display: flex;
  justify-items: left;
  align-items: center;
  margin: 15px 0 10px 20px;
}

.today > img {
  margin-right: 10px;
}

.today > p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #ffffff;
}

.item-today {
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 7px;
}

.item-today .item {
  height: 276px;
  border-radius: 24px;
  border: 4px solid #56698f;
  overflow: hidden;
  background: #3c4868;
}

.item > p {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  margin-top: 5px;
  color: #ffffff;
}

.item > .price {
  height: 60px;
  padding: 4px;
  border-radius: 6px;
  background: #ffffff33;
  margin: 5px 5px 0 5px;
  text-align: center;
}

.prix {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  color: #ffffff;
  margin: 2px 0 5px 0;
}

.price-item {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
}

.price-change {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  margin: 0;
  color: #ffffff80;
}

.unblock {
  height: 32px;
  border-radius: 9999px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  margin: 6px 5px 0 5px;
  padding: 0 5px 0 15px;
}

.unblock-text {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  color: #1f2332;
}

.unblock-number {
  font-family: Montserrat;
  margin: 0;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  background: #c7577c;
  color: #ffffff;
  padding: 4px 6px 4px 6px;
  border-radius: 9999px;
}
